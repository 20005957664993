import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ol-kanban-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

    constructor() { }

    ngOnInit() {}
}
