import { Injectable, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import TasksManager from '../behaviors/TasksManager';
import ColumnsManager from '../behaviors/ColumnsManager';
import UsersManager from '../behaviors/UsersManager';
import TimesheetManager from '../behaviors/TimesheetManager';
import RulesManager from '../behaviors/RulesManager';
import * as moment from 'moment';
import 'moment/locale/pt-br';

@Injectable({
    providedIn: 'root'
})
export class KanbanService {

    public application_id: string;
    public kanban_id: string;
    public user_id: string;

    public loading: boolean;
    public columns: Array<any>;
    public data: any;
    public messages: Array<any>;

    public columnsManager: ColumnsManager;
    public tasksManager: TasksManager;
    public usersManager: UsersManager;
    public timesheetManager: TimesheetManager;
    public rulesManager: RulesManager;

    // Emitters
    public onLoadComplete: EventEmitter<any> = new EventEmitter();

    constructor(private firestore: AngularFirestore) {
        moment.locale('pt-BR');
        this.columns = [];
        this.messages = [];
        this.data = {};

        this.tasksManager = new TasksManager(this, firestore);
        this.columnsManager = new ColumnsManager(this, firestore);
        this.usersManager = new UsersManager(this, firestore);
        this.timesheetManager = new TimesheetManager(this, firestore);
        this.rulesManager = new RulesManager(this, firestore);
    }


    public start(application_id: string, kanban_id: string, user_id: string, filters: Array<any>) {
        this.loading = true;
        this.application_id = application_id;
        this.kanban_id = kanban_id;
        this.user_id = user_id;

        // Carrega informações do kanban e  as colunas
        const loadItems: Array<Promise<any>> = [
            this.loadkanbanInfo(),
            this.rulesManager.load(),
            this.columnsManager.loadColumns()
        ];

        // Observa as promises que vão carregar as informações basicas
        Promise.all(loadItems).then(() => this.tasksManager.loadTasks(filters));
    }

    public removeSubscribes(): void {
        this.tasksManager.removeSubscribes();
    }

    public async moveTask(taskId:string, fromColumnId: string, toColumnId: string, position: number) {
        this.tasksManager.moveTask(taskId, fromColumnId, toColumnId, position);
    }


    private async loadkanbanInfo(): Promise<any> {
        this.data = {};
        return new Promise(resolve => {
            this.firestore.doc(`/kanban/${this.kanban_id}`).get().toPromise().then(docSnapshot => {
                this.data = docSnapshot.data();
                resolve();
            });
        });
    }
}
