import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import * as firebase from 'firebase/app';
import { TimesheetModalComponent } from '../timesheet-modal/timesheet-modal.component';
import { KanbanService } from '../../providers/kanban.service';

@Component({
    selector: 'app-card-modal',
    templateUrl: './card-modal.component.html',
    styleUrls: ['./card-modal.component.scss']
})
export class CardModalComponent implements OnInit {

    @Input() data: any;
    public fields: Array<any>; // FormBuilder
    public showFields: Array<any>; // Show information

    public badgesFormControl: FormControl;
    public statusFormControl: FormControl;

    constructor(
        public activeModal: NgbActiveModal,
        public kanbanService: KanbanService,
        private modalService: NgbModal
    ) { }

    ngOnInit() {

        this.loadFilters();

        // popula caixa de badges
        const badges: Array<any> = [];
        if(this.data.badges) {
            for(const badgeId of this.data.badges) {
                badges.push(this.kanbanService.data.badges[badgeId]);
            }
        }

        let status: any;
        if(this.data.status || this.data.status === 0) {
            status = this.kanbanService.data.status[this.data.status];
        }

        this.badgesFormControl = new FormControl(badges, []);
        this.statusFormControl = new FormControl(status, []);
    }

    public async loadFilters() {
        // Busca fields da coluna
        const column = this.kanbanService.columns.find(item => item.id === this.data.column);
        if(column.fields) {
            this.fields = column.fields;

            // Se não existir valor inicial cria variavel
            this.data.form = this.data.form || {};
        }
    }

    public saveBadges() {
        const badges = [];
        for(const badge of this.badgesFormControl.value) {
            badges.push(this.kanbanService.data.badges.indexOf(badge));
        }

        // Salva novos valores
        this.kanbanService.tasksManager.save(this.data.id, { badges: badges });
    }

    public saveStatus() {
        const statusIndex: number = this.kanbanService.data.status.indexOf(this.statusFormControl.value);

        // Salva status novo
        if(statusIndex !== -1) {
            this.kanbanService.tasksManager.save(this.data.id, { status: statusIndex });
        }
        else {
            // Se não existir remove chave do banco
            this.kanbanService.tasksManager.save(this.data.id, { status: firebase.firestore.FieldValue.delete() });
        }
    }

    public saveCheckbox() {
        this.kanbanService.tasksManager.save(this.data.id, { checkbox: this.data.checkbox });
    }

    public saveForm(data) {
        // Salva dados do form
        this.kanbanService.tasksManager.save(this.data.id, {
            form: Object.assign(this.data.form, data)
        });
    }

    public openTimesheet() {
        const modalRef = this.modalService.open(TimesheetModalComponent, { size: 'lg' });
        modalRef.componentInstance.data = this.data;
    }
}
