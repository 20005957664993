import { Directive, HostBinding, Output, EventEmitter, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[fileDragDrop]'
})
export class AttachmentsBoxDirective {

    @Output() onFileDropped = new EventEmitter<any>();

    constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

    //Dragstart listener
    @HostListener('dragstart', ['$event']) onDragStart(evt) {
        this.renderer.addClass(this.hostElement.nativeElement, 'dragstart');
    }

    //Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.renderer.addClass(this.hostElement.nativeElement, 'dragover');
    }

    //Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.renderer.removeClass(this.hostElement.nativeElement, 'dragover');
    }

    //Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files)
        }
        this.renderer.removeClass(this.hostElement.nativeElement, 'dragover');
    }

    //Dragend  listener
    @HostListener('dragend', ['$event']) onDragEnd(e) {
        this.renderer.removeClass(this.hostElement.nativeElement, 'dragstart');
    }
}
