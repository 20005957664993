import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CardModalComponent } from '../modal/card-modal/card-modal.component';
import { KanbanService } from '../providers/kanban.service';

@Component({
    selector: 'ol-column',
    templateUrl: './column.component.html',
    styleUrls: ['./column.component.scss']
})
export class ColumnComponent implements OnInit {

    @Input() title: string;
    @Input() cardTemplate: TemplateRef<HTMLElement>;

    public loading = false;
    public isMoving = false;
    public columnsToMove = [];
    private currentColumn: any;

    constructor(public kanban: KanbanService, private modalService: NgbModal) {}

    ngOnInit() {}

    public drop(event: CdkDragDrop<string[]>, column: any) {
        this.kanban.moveTask(
            this.currentColumn.tasks[event.previousIndex].id,
            this.currentColumn.id,
            column.id,
            event.currentIndex
        );
        this.currentColumn = null;
        this.isMoving = false;
    }

    public dragStarted(col) {
        this.isMoving = true;
        this.currentColumn = col;
        this.columnsToMove = col.targets;
    }

    public release() {
        this.columnsToMove = [];
    }

    public getCard($event) {
        console.log('getCard $event: ', $event);
    }

    public card($event) {
        console.log('card: ', $event);
    }

    public isMove(col) {
        return !this.columnsToMove || this.columnsToMove.includes(col.id);
    }

    public onClickCard(item) {
        const modalRef = this.modalService.open(CardModalComponent, { size: 'lg' });
        modalRef.componentInstance.data = item;
    }
}
