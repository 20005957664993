import { KanbanService } from '../providers/kanban.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import Validators from '../validators/validators';

export default class TasksManager {

    private kanbanRef: KanbanService;
    private tasksSub: Subscription;
    private firestore: AngularFirestore;
    private orders: any;
    private firstLoad: boolean;

    private kanbanPath: string;

    constructor(kanbanRef: KanbanService, firestore: AngularFirestore) {
        this.kanbanRef = kanbanRef;
        this.firestore = firestore;
        this.orders = {};
    }

    public removeSubscribes(): void {
        if(this.tasksSub) {
            this.tasksSub.unsubscribe();
        }
    }

    public loadTasks(filters: Array<any>) {
        this.firstLoad = true;
        this.kanbanRef.loading = true;
        this.kanbanPath = `/kanban/${this.kanbanRef.kanban_id}`;

        this.removeSubscribes();

        // remove tarefas te todas as colunas
        for(const columnToClear of this.kanbanRef.columns) {
            columnToClear.tasks = [];
        }

        // Carrega preferencias de ordem de tarefas
        this.orders = {};
        this.firestore.collection(
            `${this.kanbanPath}/user_preferences/${this.kanbanRef.user_id}/order_tasks`
        ).get().toPromise().then(docsOrder => {
            docsOrder.forEach(doc => {
                const orderData: any = Object.assign({}, doc.data());

                // converte data para js date
                orderData.update_at = orderData.update_at.toDate();

                this.orders[doc.id] = orderData;
            });

            // Carrega tarefas
            this.tasksSub = this.firestore.collection(`${this.kanbanPath}/tasks`, ref => this.configFilters(ref, filters)).stateChanges().pipe(map(
                changes => {
                    // faz tratamento do payload
                    return changes.map(a => {
                        const data: any = a.payload.doc.data();
                        data.id = a.payload.doc.id;
                        return data;
                    });
                }
            )).subscribe(result => {
                result.forEach(task => {
                    this.taskToColumn(task);
                });

                if(this.firstLoad) {
                    this.firstLoad = false;
                    this.kanbanRef.onLoadComplete.emit();
                }
                this.kanbanRef.loading = false;
            });
        })
        .catch(error => console.log(error));
    }

    private configFilters(ref: any, filters: any[]): firebase.firestore.CollectionReference {
        for(const filter of filters) {
            ref = ref.where(filter.key, filter.op, filter.value);
        }
        return ref;
    }

    public taskToColumn(task: any) {
        const data = this.getTaskWithId(task.id);

        // se existir em alguma coluna remove para realocar
        if(data) {
            data.column.tasks.splice(data.index, 1);
        }

        if(task.user_id) {
            task.user_ref = this.kanbanRef.usersManager.getUserWithId(task.user_id);
        }

        // adiciona tarefa na coluna
        const column = this.kanbanRef.columns.find(item => item.id === task.column);
        if(column) {

            // Calcula tempo em execução
            task.startAt = null;
            if(task.start_at) {
                let startAt = task.countTime || 0;
                task.startAt = startAt + (moment().diff(task.start_at.toDate(), 'seconds'))
            }
            else if(task.countTime) {
                task.startAt = task.countTime;
            }

            // Calcula checkbox
            this.calcProgressCheckbox(task);

            // Se ja existia uma tarefa anterior apenas atualiza as chaves
            // TODO - não está disparando o change
            /*if(data) {
                //remove chaves que não existem mais para manter referencia
                for(const key of Object.keys(data.task)) {
                    if(!task[key]) {
                        delete data.task[key];
                    }
                }
                // Atualiza Chaves
                column.tasks.push(Object.assign(data.task, task));
            }
            else {*/
                column.tasks.push(task);
            //}

            // sort column
            this.sortTasksWithColumn(column);
        }
    }

    private calcProgressCheckbox(task) {
        if(task.checkbox) {
            let total: number = 0;
            let complete: number = 0;

            for(const checkbox of task.checkbox) {
                for(let option of checkbox.options) {
                    total++;
                    if(option.checked === true) {
                        complete++;
                    }
                }
            }
            task.progressCheckbox = 100 * complete / total;
        }
    }

    private sortTasksWithColumn(column: any) {
        column.tasks.sort((task1: any, task2: any) =>  {
            const t1 = this.orders[task1.id];
            const t2 = this.orders[task2.id];

            // se não existir ordem
            if(!t1 && !t2) {
                return 1;
            }

            // se existir 1 e não existir 2
            if(t1 && !t2) {
                return -1;
            }

            // se existir 2 e não existir 1
            if(t2 && !t1) {
                return 1;
            }

            // ordena por ordem crescente e data decrescente
            return t1['order'] - t2['order'] || t2['update_at'] - t1['update_at'];
        });
    }

    private getTaskWithId(id: string): any {
        let task: any;
        for(const column of this.kanbanRef.columns) {
            if(column.tasks) {
                const data = column.tasks.find((item: any) => item.id === id);
                if(data) {
                    task = {
                        index: column.tasks.indexOf(data),
                        task: data,
                        column: column
                    }
                    break;
                }
            }
        }
        return task;
    }

    public async save(taskId: string, data: any) {
        return this.firestore.doc(`${this.kanbanPath}/tasks/${taskId}`).update(data);
    }

    public async moveTask(taskId:string, fromColumnId: string, toColumnId: string, position: number) {

        // Busca todas as referencias necessárias
        const task = this.getTaskWithId(taskId).task;
        const fromColumn = this.kanbanRef.columnsManager.getColumnWithID(fromColumnId);
        const toColumn = this.kanbanRef.columnsManager.getColumnWithID(toColumnId);

        task.column = toColumnId;
        task.loading = true;

        this.taskToColumn(task);

        let error: any = false;
        let message: string;

        // Checa todos os validators obrigatórios
        for(const item of Validators.DEFAULTS.moveCard) {
            if(!Validators.ALL_VALIDATORS[item.validator](this.kanbanRef, task, fromColumn, toColumn)) {
                error = true;
                message = item.message;
                break;
            }
        }

        if(error) {
            task.column = fromColumnId;
            this.taskToColumn(task);

            if(message) {
                this.kanbanRef.messages.push({
                    type: 'warning',
                    subtitle: message,
                    time: 3000
                });
            }
            task.loading = false;
        }
        // Valida regras dinamicas vindas do banco
        else if(this.kanbanRef.rulesManager.onMove(task, fromColumn, toColumn)) {

            // Salva mudança de posição
            this.orders[task.id] = {
                order: position,
                update_at: moment().toDate()
            }

            // sort column
            this.sortTasksWithColumn(toColumn);

            // Salva ordenação nova
            this.firestore.doc(
                `${this.kanbanPath}/user_preferences/${this.kanbanRef.user_id}/order_tasks/${task.id}`
            ).set(this.orders[task.id]);

            // SAVE FIREBASE
            this.save(task.id, { column: toColumnId }).then(() => {
                this.kanbanRef.messages.push({
                    type: 'success',
                    subtitle: 'Card movido',
                    time: 2000
                });
                task.loading = false;
            })
            .catch(error => {
                task.column = fromColumnId;
                this.taskToColumn(task);

                this.kanbanRef.messages.push({
                    type: 'error',
                    subtitle: 'Ocorreu um erro ao mover a tarefa'
                });
                task.loading = false;
            })
        }
    }
}
