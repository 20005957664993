import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { KanbanService } from '../providers/kanban.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export default class AttachmentService {

    constructor(private firestore: AngularFirestore, private storage: AngularFireStorage, private kanbanService: KanbanService) {}

    public upload(taskId: string, file: any) {
        const randomId = Math.random().toString(36).substring(2);
        const refUrl: string = `${this.kanbanService.application_id}/${this.kanbanService.kanban_id}/${taskId}/${randomId}`;
        const fileRef = this.storage.ref(refUrl);
        const task: AngularFireUploadTask = this.storage.upload(refUrl, file);

        const savePromise: Promise<any> = new Promise(resolve => {
            task.snapshotChanges().pipe(
                finalize(async () => resolve(this.save(taskId, {
                    url: await fileRef.getDownloadURL().toPromise(),
                    name: file.name,
                    storageUrl: refUrl
                })))
            )
            .subscribe()
        });

        return {
            uploadProgress: task.percentageChanges(),
            save: savePromise
        };
    }

    private save(taskId: string, file: any): any {
        const data = {
            ...file,
            user_id: this.kanbanService.user_id,
            create_at: moment().toDate()
        };

        const refDB: string = `kanban/${this.kanbanService.kanban_id}/tasks/${taskId}/attachments`;
        const docId: string = this.firestore.createId();

        this.firestore.doc(`${refDB}/${docId}`).set(data);

        return {
            ...data,
            id: docId,
            create_at: moment(),
            user: this.kanbanService.usersManager.getUserWithId(data.user_id)
        };
    }

    public async getAll(taskId: string): Promise<Array<any>> {
        return new Promise(resolve => {
            const data: Array<any> = [];
            const refDB: string = `kanban/${this.kanbanService.kanban_id}/tasks/${taskId}/attachments`;
            this.firestore.collection(refDB, ref => ref.orderBy('create_at', 'desc')).get().toPromise().then(snap => {
                snap.forEach(doc => {
                    data.push({
                        ...doc.data(),
                        id: doc.id,
                        user: this.kanbanService.usersManager.getUserWithId(doc.data().user_id)
                    });
                });

                resolve(data);
            });
        });
    }

    public delete(taskId: string, docId: string, storageUrl: string) {
        // remove do storage
        this.storage.ref(storageUrl).delete();

        // remove do banco
        const ref: string = `kanban/${this.kanbanService.kanban_id}/tasks/${taskId}/attachments/${docId}`;
        return this.firestore.doc(ref).delete();
    }
}
