import { Component, ViewChild } from '@angular/core';
import { ISearchFilterOptions } from 'oompa-loompa-components/dist/components/data-grid/filters/filter.interface';
import { OLDataGridFilterComponent } from 'oompa-loompa-components/dist/components/data-grid/filters/filter.component';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { KanbanService } from '../ol-kanban/providers/kanban.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
    selector: 'app-page-test',
    templateUrl: './page-test.component.html',
    styleUrls: ['./page-test.component.scss']
})
export class PageTestComponent {

    @ViewChild('filters', {static: true}) filterComponent: OLDataGridFilterComponent;

    constructor(public kanban: KanbanService, private route: ActivatedRoute, public firestore: AngularFirestore) {}

    public showAdd = false;
    public newTaskFields: Array<any> = [
        {
            label: 'Nome da Tarefa',
            key: 'title',
            type: 'ng-text',
            class: 'col-12',
            required: true
        },
        {
            label: 'Descrição',
            key: 'description',
            type: 'ng-text-area',
            class: 'col-12',
            required: true
        },
        {
            label: 'Data de entrega',
            key: 'end_date',
            type: 'ng-datetime',
            class: 'col-12',
            required: true
        }
    ];

    private badges: Array<any> = [];
    private status: Array<any> = [];
    public kanbanId: string;
    public userId: string;


    public filtersColumns: ISearchFilterOptions[] = [
        {
            id: 'period',
            label: 'Período',
            type: 'date',

        },
        {
            id: 'status',
            label: 'Status',
            type: 'select',
            options: this.status,
        },
        {
            id: 'badges',
            label: 'Marcador',
            type: 'select',
            options: this.badges,
        }
    ];

    public baseFilters: Array<any> = [
        //{ key: 'end_date', op: '>=', value: moment().startOf('month').toDate() },
        //{ key: 'end_date', op: '<=', value: moment(moment().endOf('month').format('DD/MM/YYYY23:59:59'), 'DD/MM/YYYYHH:mm:ss').toDate() }
    ];


    ngOnInit() {
        this.kanbanId = this.route.snapshot.params.kanban_id;
        this.userId = this.route.snapshot.params.user_id;
    }


    public getFilters($event: any) {
        console.log('getFilters $event: ', $event);

        const filters = [];
        for(const filter of $event) {
            if(filter.id == 'period') {
                if(!filter.value.end || !filter.value.start) {
                    this.filterComponent.removeLastFilter();
                    this.kanban.messages.push({type: 'danger', title: 'Erro', subtitle: 'Defina um período inicial e final'});
                    return;
                }
                else {
                    const startDate: moment.Moment = moment(filter.value.start + '00:00:00', 'DD/MM/YYYYHH:mm:ss');
                    const endDate: moment.Moment = moment(filter.value.end + '23:59:59', 'DD/MM/YYYYHH:mm:ss');

                    // Não permite período maior que 30 dias
                    // Para não sobrecarregar as buscas
                    if(startDate.diff(endDate, 'days') > 31) {
                        this.filterComponent.removeLastFilter();
                        this.kanban.messages.push({type: 'danger', title: 'Erro', subtitle: 'O período deve ser no máximo de 1 mês'});
                        return;
                    }
                    else {
                        filters.push({ key: 'end_date', op: '>=', value: startDate.toDate() });
                        filters.push({ key: 'end_date', op: '<=', value: endDate.toDate() });
                    }
                }
            }
            else if(filter.id == 'badges') {
                filters.push({ key: filter.id, op: 'array-contains', value: Number(filter.value) });
            }
            else {
                filters.push({
                    key: filter.id, // Chave do objeto
                    op: '==', // Operador
                    value: (!filter.id.includes('id') && filter.type == 'select') ? Number(filter.value) : filter.value // Valor a ser buscado
                });
            }
        }
        this.kanban.tasksManager.loadTasks(filters);
    }

    public onKanbanLoad() {
        // Load badges
        this.badges.splice(0, this.badges.length);
        for(const index in this.kanban.data.badges) {
            this.badges.push({label: this.kanban.data.badges[index].title, value: index});
        }

        // Load status
        this.status.splice(0, this.status.length);
        for(const index in this.kanban.data.status) {
            this.status.push({label: this.kanban.data.status[index].title, value: index});
        }
    }

    public onSave(data) {
        data.user_id = '106';

        // tratamento provisório da data
        data.end_date = (data.end_date as moment.Moment).set({hour:18, minute:0, second:0, millisecond:0});
        data.end_date = data.end_date.toDate();
        data.create_by = this.userId;

        data.column = this.kanban.columns[0].id;
        console.log(data);
        this.showAdd = false;
        this.firestore.collection(`/kanban/${this.kanbanId}/tasks`).add(data);
    }
}
