import { KanbanService } from '../providers/kanban.service';

export default class ColumnsValidator {

    public static existUserTask(kanbanService: KanbanService, task: any, fromColumn: any, toColumn: any): boolean {

        if(task.user_id && toColumn.count_time && toColumn.count_time === true) {

            const item = toColumn.tasks.find((item: any) => item.user_id == task.user_id && item.id !== task.id);

            if(item) return false;

            // Se está indo para uma coluna que conta tempo atualiza a referencia de timer
            // Verifica se não está movendo dentro da própria coluna
            if(fromColumn.id !== toColumn.id) {
                // gerencia timesheet para contar tempo
                kanbanService.timesheetManager.moveTaskAndCount(task, toColumn);
            }

            return true;
        }

        // Se existir timesheet contando, marca finaliza tempo
        kanbanService.timesheetManager.moveTaskAndStop(task);

        return true;
    }
}
