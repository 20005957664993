import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'ol-kanban-toast',
	templateUrl: './kanban-toast.component.html',
	styleUrls: ['./kanban-toast.component.scss']
})
export class KanbanToastComponent implements OnInit {

	@Input() public message: { title?: string, subtitle?: string, type?: 'danger' | 'warning' | 'success', time?: number };
	@Output() public close: EventEmitter<{ title?: string, subtitle?: string, type?: 'danger' | 'warning' | 'success', time?: number }>;

	constructor() {
		this.close = new EventEmitter();
	}

	ngOnInit() {
		if (this.message.time) {
			setTimeout(() => this.closeToastr(), this.message.time);
		}
	}

	/**
	* Emite a mensagem do componente para ser fechada
	*/
	public closeToastr(): void {
		this.close.emit(this.message);
	}

}
