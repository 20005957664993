import { KanbanService } from '../providers/kanban.service';
import { AngularFirestore } from '@angular/fire/firestore';

export default class ColumnsManager {

    private kanbanRef: KanbanService;
    private firestore: AngularFirestore;

    constructor(kanbanRef: KanbanService, firestore: AngularFirestore) {
        this.kanbanRef = kanbanRef;
        this.firestore = firestore;
    }

    public async loadColumns(): Promise<any> {
        this.kanbanRef.columns = [];
        return new Promise(resolve => {
            this.firestore.collection(`/kanban/${this.kanbanRef.kanban_id}/columns`, ref => ref.orderBy('order')).get().toPromise().then(data => {
                data.forEach(doc =>
                    this.kanbanRef.columns.push(Object.assign({id: doc.id, tasks: []}, doc.data()))
                );
                resolve();
            });
        });
    }

    public getColumnWithID(columnId: string): any {
        return this.kanbanRef.columns.find(column => column.id === columnId);
    }
}
