import { Component, OnInit, Input, TemplateRef, EventEmitter, Output } from '@angular/core';
import { IKanban } from './interfaces/default.interface';
import { KanbanService } from './providers/kanban.service';

@Component({
    selector: 'ol-kanban',
    templateUrl: './ol-kanban.component.html',
    styleUrls: ['./ol-kanban.component.scss']
})
export class OLKanbanComponent implements OnInit {

    @Input() applicationId: string;
	@Input() kanbanId: string;
    @Input() config: IKanban;
    @Input() userId: string;
	@Input() userGroups: number;
	@Input() cardTemplate: TemplateRef<HTMLElement>;
    @Input() baseFilters: any[];

    @Output() onLoadComplete: EventEmitter<any> = new EventEmitter();

	public messages: { title?: string, subtitle?: string, type?: 'danger' | 'warning' | 'success', time?: number }[];

    constructor(public kanbanService: KanbanService) {}

    ngOnInit() {
		this.messages = this.kanbanService.messages;
        console.log(this.baseFilters);
		this.kanbanService.start(this.applicationId, this.kanbanId, this.userId, this.baseFilters);

        // outputs
        this.kanbanService.onLoadComplete = this.onLoadComplete;
	}

    ngOnDestroy() {
        this.kanbanService.removeSubscribes();
    }

	/**
	 * Remove do array de mensagens a mensagem de toastr recebida no evento
	 * @param toastr Mensagem a ser removida do array
	 */
	public closeToastr(toastr: any): void {
		const index = this.messages.indexOf(toastr);
		this.messages.splice(index, 1);
	}
}
