import { KanbanService } from '../providers/kanban.service';
import { AngularFirestore } from '@angular/fire/firestore';

export default class RulesManager {

    private kanbanRef: KanbanService;
    private firestore: AngularFirestore;
    private rules: Array<any>;

    constructor(kanbanRef: KanbanService, firestore: AngularFirestore) {
        this.kanbanRef = kanbanRef;
        this.firestore = firestore;
        this.rules = [];
    }

    public load() {
        return new Promise(resolve => {
            this.firestore.collection(`/kanban/${this.kanbanRef.kanban_id}/rules`).get().toPromise().then(data => {
                this.rules = [];
                data.forEach(doc => this.rules.push({id: doc.id, ...doc.data()}) );
                resolve();
            });
        });
    }

    public onMove(task: any, fromColumn: any, toColumn: any): boolean {
        let error: string;

        this.rules.forEach(rule => {
            if(rule.type === 'onMove') {

                // Verifica se é da coluna a ser validada
                if(rule.fromColumn === fromColumn.id && rule.toColumn === toColumn.id) {

                    console.log('RULE', eval(rule.rule), rule.rule);
                    // Aplica regra dinamica
                    if(!eval(rule.rule)) {
                        error = rule.message;
                    }
                }
            }
        });

        if(error) {
            task.column = fromColumn.id;
            this.kanbanRef.tasksManager.taskToColumn(task);

            if(error) {
                this.kanbanRef.messages.push({
                    type: 'warning',
                    subtitle: error
                });
            }
        }

        return error ? false : true;
    }
}
