import TasksValidator from './TasksValidator';
import ColumnsValidator from './ColumnsValidator';

export default class Validators {

    public static ALL_VALIDATORS = {
        'validTarget': TasksValidator.validTarget,
        'countTimeExist': ColumnsValidator.existUserTask
    }

    public static DEFAULTS = {
        moveCard: [
            { validator: 'validTarget', message: 'Você não pode mover o card para essa coluna' },
            { validator: 'countTimeExist', message: 'Já existe um card contando tempo nessa coluna' }
        ]
    };
}
