import { Component, OnInit, Input } from "@angular/core";
import AttachmentService from '../../providers/attachment.service';

@Component({
    selector: "ol-attachments-box",
    templateUrl: "./attachments-box.component.html",
    styleUrls: ["./attachments-box.component.scss"],
})
export class AttachmentsBoxComponent implements OnInit {
    @Input() public card: any;

    public files: any = [];

    constructor(private attachmentService: AttachmentService) {}

    ngOnInit() {
        this.attachmentService
            .getAll(this.card.id)
            .then((list) => (this.files = list));
    }

    public uploadFile(event) {
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            const data = this.attachmentService.upload(this.card.id, element);
            const fileInfo = {
                name: element.name,
                uploadProgress: data.uploadProgress,
            };
            this.files.unshift(fileInfo);

            data.save.then((info) => {
                this.files[this.files.indexOf(fileInfo)] = info;
            });
        }
    }

    public deleteAttachment(index) {
        this.attachmentService.delete(
            this.card.id,
            this.files[index].id,
            this.files[index].storageUrl
        );
        this.files.splice(index, 1);
    }
}
