import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { OLDataGridModule } from 'oompa-loompa-components/dist/components/data-grid/data-grid.module';
import { PageTestComponent } from './page-test/page-test.component';
import { PageTestModule } from './page-test/page-test.module';
import { OLKanbanModule } from './ol-kanban/ol-kanban.module';
import {registerLocaleData} from '@angular/common';
import br from '@angular/common/locales/br';
import { CdTimerModule } from 'angular-cd-timer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormModule } from 'oompa-loompa-components/dist/components/dynamic_form/dynamic-form.module';

registerLocaleData(br, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent,
        PageTestComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        OLKanbanModule,
        OLDataGridModule,
        DynamicFormModule,
        PageTestModule,
        NgbModule,
        CdTimerModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
