import { KanbanService } from '../providers/kanban.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import * as firebase from 'firebase/app';

export default class TimesheetManager {

    private kanbanRef: KanbanService;
    private firestore: AngularFirestore;

    constructor(kanbanRef: KanbanService, firestore: AngularFirestore) {
        this.kanbanRef = kanbanRef;
        this.firestore = firestore;
    }

    public moveTaskAndCount(task: any, toColumn: any) {
        if(task.ref_timesheet) {
            task.ref_timesheet.update({ end_at: moment().toDate() });
        }

        task.ref_timesheet = this.firestore.doc(`kanban/${this.kanbanRef.kanban_id}/timesheet/${this.firestore.createId()}`).ref;

        // salva novo dado de timesheet
        const saveData = {
            task_id: task.id,
            start_at: moment().toDate(),
            user_id: this.kanbanRef.user_id,
            column: toColumn.id
        };
        task.ref_timesheet.set(saveData);

        // salva referencia do timesheet atual na tarefa
        this.kanbanRef.tasksManager.save(task.id, {
            ref_timesheet: task.ref_timesheet,
            start_at: saveData.start_at
        });
    }

    public moveTaskAndStop(task: any) {
        if(task.ref_timesheet) {
            // atualiza hora
            task.ref_timesheet.update({ end_at: moment().toDate() });

            // remove referencia do contador de tempo
            this.kanbanRef.tasksManager.save(task.id, {
                ref_timesheet: firebase.firestore.FieldValue.delete(),
                start_at: firebase.firestore.FieldValue.delete()
            });
        }
    }

    public async update(timesheetId: string, start: Date, end: Date) {
        const refDB: string = `kanban/${this.kanbanRef.kanban_id}/timesheet/${timesheetId}`;
        return this.firestore.doc(refDB).update({
            start_at: start,
            end_at: end
        });
    }

    public async getAll(taskId: string): Promise<Array<any>> {
        return new Promise(resolve => {
            const data: Array<any> = [];
            const refDB: string = `kanban/${this.kanbanRef.kanban_id}/timesheet`;
            this.firestore.collection(refDB, (ref: any) => {
                ref = ref.where('task_id', '==', taskId);
                ref = ref.orderBy('end_at', 'desc');
                return ref;
            }).get().toPromise().then(snap => {
                snap.forEach(doc => {
                    data.push({
                        ...doc.data(),
                        id: doc.id,
                        user: this.kanbanRef.usersManager.getUserWithId(doc.data().user_id)
                    });
                });

                resolve(data);
            });
        });
    }
}
