import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { KanbanService } from '../../providers/kanban.service';

@Component({
    selector: 'app-timesheet-modal',
    templateUrl: './timesheet-modal.component.html',
    styleUrls: ['./timesheet-modal.component.scss']
})
export class TimesheetModalComponent implements OnInit {

    @Input() data: any;

    public registers: Array<any>;
    public displayedColumns: string[] = ['name', 'start', 'end', 'edit'];
    public mask_hours = [
        /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/
    ];

    constructor(public activeModal: NgbActiveModal, private kanbanService: KanbanService) { }

    ngOnInit() {
        this.kanbanService.timesheetManager.getAll(this.data.id).then(data => this.registers = data);
    }

    public editLine(element) {
        element.editing = true;
        element.edit_start = moment(element.start_at.toDate()).format('DD/MM/YYYY HH:mm:ss');
        element.edit_end = moment(element.end_at.toDate()).format('DD/MM/YYYY HH:mm:ss');
    }

    public save(element) {
        const start = moment(element.edit_start, 'DD/MM/YYYY HH:mm:ss');
        const end = moment(element.edit_end, 'DD/MM/YYYY HH:mm:ss');

        if(start.isValid() && end.isValid() && start < end) {
            element.start_at = start;
            element.end_at = end;
            element.editing = false;
            this.kanbanService.timesheetManager.update(element.id, start.toDate(), end.toDate());
        }
    }
}
