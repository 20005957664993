import { Component, OnInit, Input, ViewChild, ElementRef, Inject } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { KanbanService } from '../ol-kanban/providers/kanban.service';

@Component({
    selector: 'card-budget',
    templateUrl: './card-budget.component.html',
    styleUrls: ['./card-budget.component.scss'],
})
export class CardBudgetComponent implements OnInit {

    // card element
    @ViewChild('card', { static: true }) card: ElementRef;

    @Input() public set data(data) {
        this._data = data;
        this.processing();
    }

    public get data() {
        return this._data;
    }

    public status: { color: string, title: string };
    public badges: { color: string, title: string }[];
    private _data: any;
    private $dragInDropObservable: Subscription;
    public isStart: boolean = false;
    public isLate: any;

    constructor(@Inject(DOCUMENT) private document: Document, private kanban: KanbanService) {
        moment.locale('pt-br');
    }

    ngOnInit() {
        this.dragInDrop();
    }

    ngOnDestroy() {
        this.$dragInDropObservable.unsubscribe();
    }

    public getHours(time: number): string {
        const secondsTotal = time;
        const hours = Math.floor(secondsTotal / 3600);
        let minutes = Math.floor((secondsTotal - hours * 3600) / 60).toString();
        minutes = minutes.length == 1 ? '0' + minutes : minutes;
        let seconds = (
            secondsTotal -
            (hours * 3600 + parseInt(minutes) * 60)
        ).toString();
        seconds = seconds.length == 1 ? '0' + seconds : seconds;

        return hours + ':' + minutes + ':' + seconds;
    }

    public dragInDrop() {
        const el = this.card.nativeElement as HTMLDivElement;
        const mouseDown$ = fromEvent(el, 'mousedown');
        const mouseMove$ = fromEvent(this.document, 'mousemove');
        const mouseUp$ = fromEvent(this.document, 'mouseup');
        const wrapper = document.querySelector('.kanban__area') as HTMLDivElement;
        const width = wrapper.clientWidth;
        const scrollX = document.querySelector('.kanban__scroll-x');

        this.$dragInDropObservable = mouseDown$.pipe(
            map((e: any) => ({
                x: e.clientX,
                y: e.clientY,
                target: {
                    x: e.target.offsetLeft,
                    y: e.target.offsetTop,
                    w: e.target.offsetWidth,
                    h: e.target.offsetHeight,
                },
            })),
            switchMap((start: any) => mouseMove$.pipe(
                map((e: any) => ({
                    x: this.moveScroll(e, start, width, scrollX),
                })),
                takeUntil(mouseUp$)
            ))
        ).subscribe();
    }

    private moveScroll(move, start, width, scrollX) {
        if (move.x + 230 > width / 3 * 2) {
            scrollX.scrollLeft += 5;
        } else {
            if (start.x > move.x) {
                scrollX.scrollLeft -= 4;
            }
        }
    }

    /**
    * Recupera do KanbanService os dados de estilização
    */
    private processing(): void {
        this.status = this.kanban.data.status[this.data.status] || '';
        this.badges = this.kanban.data.badges;

        // Verifica se pode dar start no tempo
        this.isStart = false;
        if(this.data.start_at) {
            this.isStart = true;
        }

        // Check is valid
        if(this.data.end_date) {
            const endDate: moment.Moment = moment(this.data.end_date.toDate());
            const now: moment.Moment = moment();

            if(now > endDate) {
                const diff = endDate.diff(now);

                this.isLate = moment.duration(diff).humanize();
            }
        }
    }
}
