import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OLDataGridModule } from 'oompa-loompa-components/dist/components/data-grid/data-grid.module';
import { OLKanbanModule } from '../ol-kanban/ol-kanban.module';
import { CardBudgetComponent } from '../card-budget/card-budget.component';
import { CdTimerModule } from 'angular-cd-timer';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DynamicFormModule } from 'oompa-loompa-components/dist/components/dynamic_form/dynamic-form.module';


@NgModule({
    declarations: [
        CardBudgetComponent
    ],
    imports: [
        CommonModule,
        OLKanbanModule,
        OLDataGridModule,
        MatProgressBarModule,
        DynamicFormModule,
        NgbModule,
        CdTimerModule
    ],
    exports: [
        CardBudgetComponent
    ]
})
export class PageTestModule { }
