export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyCxtENKAlWLC2RiwWfpza9fQRc6gGAMI9s",
        authDomain: "controlecontabilidade-ae2df.firebaseapp.com",
        databaseURL: "https://controlecontabilidade-ae2df.firebaseio.com",
        projectId: "controlecontabilidade-ae2df",
        storageBucket: "controlecontabilidade-ae2df.appspot.com",
        messagingSenderId: "738445764113",
        appId: "1:738445764113:web:8ff40ba60bb1882a9c73b0"
    }
};
