import { Component, TemplateRef, Input } from '@angular/core';

@Component({
    selector: 'container-component',
	template: `
        <ng-container
            [ngTemplateOutlet]="cardTemplate || defaultTemplate"
            [ngTemplateOutletContext]="{ $implicit: data }"
        ></ng-container>

		<ng-template #defaultTemplate let-data>
            <div class="default">Sem cards</div>
        </ng-template>
    `,
    styles: [`
        :host {
            display: block;
            transform: scale(1);
            transition: 0.2s;
        }

        .default {
            padding: 8px;
            border: 1px solid #CCCCCC;
            border-radius: 4px;
            margin-bottom: 8px;
        }

        :host:hover {
            transform: scale(1.05);
        }
    `]
})
export class ContainerCardComponent {

	@Input() data: any;
    @Input() cardTemplate: TemplateRef<HTMLElement>;
}
