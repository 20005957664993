import { KanbanService } from '../providers/kanban.service';
import { AngularFirestore } from '@angular/fire/firestore';

export default class UsersManager {

    private kanbanRef: KanbanService;
    private firestore: AngularFirestore;
    public users: any;

    constructor(kanbanRef: KanbanService, firestore: AngularFirestore) {
        this.kanbanRef = kanbanRef;
        this.firestore = firestore;

        this.users = {};
    }

    private loadUser(userId: string) {
        this.firestore.doc(`applications/${this.kanbanRef.application_id}/users/${userId}`).get().toPromise().then(data => {
            this.users[userId] = Object.assign(this.users[userId], data.data());
        })
        .catch(error => {
            console.log(error);
            this.users[userId] = Object.assign(this.users[userId], { name: 'Erro ao buscar Usuário' });
        });
    }

    public getUserWithId(id: string): any {
        if(!this.users[id]) {
            this.users[id] = { name: 'Carregando...' };
            this.loadUser(id);
        }

        return this.users[id];
    }
}
