import { KanbanService } from '../providers/kanban.service';

export default class TasksValidator {

    public static validTarget(_kanbanService: KanbanService, _task: any, fromColumn: any, toColumn: any): boolean {

        if(fromColumn.targets) {
            return fromColumn.targets.includes(toColumn.id) || fromColumn.id === toColumn.id;
        }

        return true;
    }
}
