import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import CommentsService from '../../providers/comments.service';
import { KanbanService } from '../../providers/kanban.service';

@Component({
    selector: 'ol-comments-box',
    templateUrl: './comments-box.component.html',
    styleUrls: ['./comments-box.component.scss']
})
export class CommentsBoxComponent implements OnInit {

    @Input() card: any;

    public formControl: FormControl;
    public allComments: Array<any>;

    constructor(private commentsService: CommentsService, private kanbanService: KanbanService) {
        this.allComments = [];
    }

    ngOnInit() {
        this.formControl = new FormControl('', []);
        this.commentsService.getAll(this.kanbanService.kanban_id, this.card.id).then(data => {
            data.forEach(doc => doc.user = this.kanbanService.usersManager.getUserWithId(doc.user_id));
            this.allComments = data;
        });
    }

    public saveComment() {
        if(this.formControl.value && this.formControl.value.trim()) {
            const comment = this.commentsService.save(
                this.kanbanService.kanban_id,
                this.kanbanService.usersManager.getUserWithId(this.kanbanService.user_id),
                this.card.id,
                this.formControl.value.trim()
            );
            this.allComments.unshift(comment);
            this.formControl.setValue('');
        }
    }
}
