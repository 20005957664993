import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OLKanbanComponent } from './ol-kanban.component';
import { KanbanToastComponent } from './kanban-toast/kanban-toast.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { ColumnComponent } from './column/column.component';
import { ContainerCardComponent } from './card/container-card.component';
import { CardModalComponent } from './modal/card-modal/card-modal.component';
import { CommentsBoxComponent } from './modal/comments-box/comments-box.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AttachmentsBoxComponent } from './modal/attachments-box/attachments-box.component';
import { AttachmentsBoxDirective } from './modal/attachments-box/attachments-box.directive';
import { TimesheetModalComponent } from './modal/timesheet-modal/timesheet-modal.component';
import { CdTimerModule } from 'angular-cd-timer';
import { TextMaskModule } from 'angular2-text-mask';
import { DynamicFormModule } from 'oompa-loompa-components/dist/components/dynamic_form/dynamic-form.module';
import { KanbanService } from './providers/kanban.service';
import CommentsService from './providers/comments.service';
import AttachmentService from './providers/attachment.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

const COMPONENTS = [
    KanbanToastComponent,
    OLKanbanComponent
];

const COMPONENTS_PRIVATE = [
    SkeletonComponent,
    ColumnComponent,
    ContainerCardComponent,
    CommentsBoxComponent,
    AttachmentsBoxComponent,
    AttachmentsBoxDirective
];

const ENTRY_COMPONENTS = [
    CardModalComponent,
    TimesheetModalComponent
];

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AngularFireModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
        DynamicFormModule,
        DragDropModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        MatBadgeModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatCheckboxModule,
        MatProgressBarModule,
        TextMaskModule,
        CdTimerModule,
        NgxSkeletonLoaderModule,
        NgbModule
    ],
    exports: [
        ...COMPONENTS
    ],
    declarations: [
        ...COMPONENTS,
        ...COMPONENTS_PRIVATE,
        ...ENTRY_COMPONENTS
    ],
    entryComponents: [
        ...ENTRY_COMPONENTS
    ],
    providers: [
        KanbanService
    ]
})
export class OLKanbanModule { }
