import { AngularFirestore } from '@angular/fire/firestore';
import { KanbanService } from '../providers/kanban.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export default class CommentsService {

    constructor(private firestore: AngularFirestore) {}

    public save(kanbanId: string, user: any, taskId: string, comment: string): any {
        const data = {
            user_id: user.id,
            message: comment,
            create_at: moment().toDate()
        };

        const refDB: string = `kanban/${kanbanId}/tasks/${taskId}/comments`;
        this.firestore.collection(refDB).add(data);

        return {
            ...data,
            create_at: moment(),
            user: user
        };
    }

    public async getAll(kanbanId: string, taskId: string): Promise<Array<any>> {
        return new Promise(resolve => {
            const data: Array<any> = [];
            const refDB: string = `kanban/${kanbanId}/tasks/${taskId}/comments`;
            this.firestore.collection(refDB, ref => ref.orderBy('create_at', 'desc')).get().toPromise().then(snap => {
                snap.forEach(doc => {
                    data.push({
                        ...doc.data(),
                        id: doc.id
                    });
                });

                resolve(data);
            });
        });
    }
}
